<template>
  <div class="editRackCard">
    <b-card
      img-src="https://www.jax.org/-/media/0586dfc4d83842cca60fde459c567617.jpg"
      img-alt="Image"
      img-top
      style="max-width: 20rem;"
    >

      <!-- Rack Name -->
      <b-row class="my-1">
        <b-col sm="4">
          <label for="input-small">Name:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input id="input-small" size="sm" placeholder="Room Name" v-model="name"></b-form-input>
        </b-col> 
      </b-row> 

      <!-- Rack Type & type -->
      <b-row class="my-1">
        <b-col sm="4">
          <label for="input-small">Type:</label>
        </b-col>
        <b-col sm="8">
          <b-form-select style="width: 100%" v-model="type" :options="options"></b-form-select>
        </b-col> 
      </b-row> 

      <b-row class="my-1">
        <b-col sm="4">
          <label for="input-small">Next rack change:</label>
        </b-col>
        <b-col sm="8">
          <b-form-input 
            id="input-small" 
            size="sm" 
            type="date" 
            v-model="nextChangeDate"
          />
        </b-col> 
      </b-row> 
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'EditRackCard',
  props: {
    roomId: String,
    rackId: String
  },
  data() {
    return {
      options: [
        'Mouse 10x8',
        'Rat 5x7'
      ]
    }
  },
  computed: {
    rack() {
      return this.$store.state.racks.find(r => r.rackId === this.rackId)
    },
    name: {
      get() {
        return this.rack.name
      },
      set(val) {
        this.$store.commit('updateRack', {
          rackId: this.rackId,
          rack: val
        })
      }
    },
    type: {
      get() {
        return this.rack.type
      },
      set(val) {
        this.$store.commit('updateRack', {
          rackId: this.rackId,
          type: val
        })
      }
    },
    nextChangeDate: {
      get() {
        return this.rack.nextChangeDate
      },
      set(val) {
        this.$store.commit('updateRack', {
          rackId: this.rackId,
          nextChangeDate: val
        })
      }
    }
  },
  created() {
    console.log("init date: ", this.nextChangeDate)
  }
}
</script>

<style scoped>
.editRackCard {
  border: 1px solid transparent;
  border-radius: 4px;
}

</style>