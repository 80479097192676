<template>
  <div class="roomCard">
    <router-link :to="{ name: 'room', params: {roomId: roomId, room: room.room} }">
      <b-card
        v-bind:title="room.room"
        v-bind:sub-title="room.immuneStatus"
        img-src="https://www.jax.org/-/media/0586dfc4d83842cca60fde459c567617.jpg"
        img-alt="Image"
        img-top
        style="max-width: 20rem;"
      > 
        <b-card-text>
          {{ room.availability }} Cage(s) available
          <br>
          {{ room.racks }} Rack(s)
        </b-card-text>

      </b-card>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RoomCard',
  props: {
    roomId: String
  },
  computed: {
    room() {
      return this.$store.state.rooms.find(r => r.roomId === this.roomId)
    }
  }
}
</script>

<style scoped>
.roomCard {
  border: 1px solid transparent;
  border-radius: 4px;
}

.roomCard:hover {
  border: 1px solid rgb(0, 204, 255);
}
a {
  text-decoration: none;
  color: black;
}

/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: black;
}
</style>