import Vue from 'vue'
import Vuex from 'vuex'
import ENUM from "@/enums";

Vue.use(Vuex)

const genPosKey = (i, j) => `${i}-${j}`
const splitPosKey = (key) => key.split('-').map(i => parseInt(i))

const orderCagePos = (pos1, pos2) => {
  const cages = [pos1, pos2]

  return cages.sort((a, b) => {
    const [ai, aj] = splitPosKey(a)
    const [bi, bj] = splitPosKey(b)

    if (ai === bi) {
      return aj - bj
    } else {
      return ai - bi
    }
  })
}

export default new Vuex.Store({
  state: {
    accepted: false,
    apiState: ENUM.INIT,
    cages: [],
    rooms: [],
    racks: [],
    selectedPositions: {},
    selectedStack: []
    // ...other state
  },
  mutations: {
    accept (state) {
      state.accepted = true
    },
    ///// RACKS
    setRacks (state, racks) {
      state.racks = racks
      state.apiState = ENUM.LOADED
    },
    updateRack (state, rack) {
      for (const r of state.racks) {
        if (r.rackId === rack.rackId) {
          Object.keys(rack).forEach(key => {
            r[key] = rack[key] || r[key]
          })
        }
      }
    },
    ///// ROOMS
    setRooms (state, rooms) {
      state.rooms = rooms
      state.apiState = ENUM.LOADED
    },
    updateRoom (state, room) {
      for (const r of state.rooms) {
        if (r.roomId === room.roomId) {
          r.room = room.room || r.room 
          r.immuneStatus = room.immuneStatus || r.immuneStatus
        }
      }
    },
    ///// CAGES
    setCages (state, cages) {
      state.cages = cages;
      state.apiState = ENUM.LOADED;
    },
    setApiState (state, apiState) {
      state.apiState = apiState;
    },
    selectCage (state, {i, j}) {
      state.cages[i][j].selected = true

      const posKey = genPosKey(i, j)
      state.selectedPositions[posKey] = true
    },
    unselectCage (state, {i, j}) {
      const posKey = genPosKey(i, j)

      if (state.selectedPositions[posKey]) {
        state.cages[i][j].selected = false
        delete state.selectedPositions[posKey]
      }
    },
    pushSelectedStack (state, {i, j}) {
      state.selectedStack.push(genPosKey(i, j))
    },
    removeSelectedStack (state, {i, j}) {
      state.selectedStack = state.selectedStack.filter(key => key !== genPosKey(i, j))
    },
    clearSelectedStack (state) {
      state.selectedStack = []
    }
  },
  actions: {
    accept (context) {
      context.commit('accept')
    },
    ///// RACKS
    loadRacks (context) {
      if (context.state.racks.length) {
        return
      }

      context.commit('setApiState', ENUM.LOADING);
      
      const racks = [{
        rackId: '1234',
        name: '1A',
        type: 'Mouse 10x8',
        availability: '30 / 80',
        animals: "150",
        sequential: "10",
        nextChangeDate: "2022-01-01"
      }]

      context.commit('setRacks', racks)
    },
    updateRack (context, newRack) {
      console.log('updating rack')
      context.commit('updateRack', newRack)
    },
    ///// ROOMS
    loadRooms (context) {
      if (context.state.rooms.length) {
        return
      }
      
      context.commit('setApiState', ENUM.LOADING);

      const rooms = [{
        roomId: '1234',
        room: 'LL-240',
        immuneStatus: 'PDX',
        availability: '30 / 80',
        racks: "1"
      },{
        roomId: '5678',
        room: 'LL-310',
        immuneStatus: 'Immune Competent',
        availability: '70 / 80',
        racks: "1"
      },{
        roomId: '9012',
        room: 'LL-315',
        immuneStatus: 'Immune Deficient',
        availability: '100 / 160',
        racks: "2"
      },{
        roomId: '3456',
        room: 'LL-480',
        immuneStatus: 'Immune Competent',
        availability: '0 / 80',
        racks: "1"
      }]

      context.commit('setRooms', rooms)
    },
    updateRoom (context, newRoom) {
      context.commit('updateRoom', newRoom)
    },
    ///// CAGES
    loadCages (context) {
      context.commit('setApiState', ENUM.LOADING);
      const grid = []

      for (let row = 0; row < 10; row++) {
        grid.push([])
        for (let column = 0; column < 8; column++) {
          const cage = {
            sentinel: false,
            selected: false,
            // barcode: `${row}-${column}`
            barcode: ''
          }

          grid[row].push(cage)
        }
      } 

      grid[9][7].sentinel = true
      grid[9][7].barcode = '0000'
      context.commit('setCages', grid)
    },
    selectCage({commit, state}, gridPos) {
      const lastSelected = state.selectedStack[state.selectedStack.length-1]
      const selectedSizePrior = state.selectedStack.length


      Object.keys(state.selectedPositions).forEach(key => {
        const [i, j] = splitPosKey(key)
        commit('unselectCage', {i, j})
      })
      commit('clearSelectedStack')

      if (lastSelected === genPosKey(gridPos.i, gridPos.j) && selectedSizePrior === 1) {
        return
      }

      commit('selectCage', gridPos)
      commit('pushSelectedStack', gridPos)
    },
    selectCtrlCage({commit, state}, {i, j}) {
      const posKey = genPosKey(i, j)

      if (state.selectedPositions[posKey]) {
        commit('unselectCage', {i, j})
        commit('removeSelectedStack', {i, j})
      } else {
        commit('selectCage', {i, j})
        commit('pushSelectedStack', {i, j})
      }
    },
    selectShiftCage({commit, state}, {i, j}) {
      const lastSelected = state.selectedStack[state.selectedStack.length-1]

      // if lastSelected exists, select all cages between last selected and input
      if (lastSelected) {
        // Clear existing selected cages if they exist
        Object.keys(state.selectedPositions).forEach(key => {
          const [_i, _j] = key.split('-')
          commit('unselectCage', {i: _i, j: _j})
        })

        // Remove last selected from stack
        commit('clearSelectedStack')

        // Order last selected and current so top left is first
        const [first, sec] = orderCagePos(lastSelected, genPosKey(i, j))

        const [firstI, firstJ] = splitPosKey(first) // top left
        const [secI, secJ] = splitPosKey(sec) // bottom right

        for (let i = secI; i >= firstI; i--) {
          let start = state.cages[0].length-1; // right most column
          let end = 0
          if (i === secI) {
            // first row, start at right most selected column
            start = secJ
          } 
          if (i === firstI) {
            // last row, end at left most selected column
            end = firstJ
          }

          for (let j = start; j >= end; j--) {
            commit('selectCage', {i, j})
            commit('pushSelectedStack', {i, j})
          }
        }
      } else {
        Object.keys(state.selectedPositions).forEach(key => {
          const [i, j] = key.split('-')
          commit('unselectCage', {i, j})
        })
        commit('selectCage', {i, j})
        commit('pushSelectedStack', {i, j})
      }
    },
    randomizeCages({commit, state}) {
      const cagesCopy = state.cages.map(i => i.map(j => j))

      state.selectedStack.forEach(posKey => {
        const [i, j] = splitPosKey(posKey)
        cagesCopy[i][j].barcode = 'RAND'
      })
      commit('setCages', cagesCopy)
    },
    generateAndAssignBarcodes({commit, state}, startingBarcode) {
      const cagesCopy = state.cages.map(i => i.map(j => j))
      console.log(typeof(startingBarcode))

      const numSelected = state.selectedStack.length

      state.selectedStack.forEach((posKey, index) => {
        const [i, j] = splitPosKey(posKey)
        cagesCopy[i][j].barcode = startingBarcode + numSelected - (index + 1)
      })
      commit('setCages', cagesCopy)
    },
    deleteCages({commit, state}) {
      const cagesCopy = state.cages.map(i => i.map(j => j))

      state.selectedStack.forEach(posKey => {
        const [i, j] = splitPosKey(posKey)
        cagesCopy[i][j].barcode = ''
        cagesCopy[i][j].sentinel = false
      })
      commit('setCages', cagesCopy)
    },
    makeCagesSentinel({commit, state}) {
      const cagesCopy = state.cages.map(i => i.map(j => j))

      state.selectedStack.forEach(posKey => {
        const [i, j] = splitPosKey(posKey)
        cagesCopy[i][j].sentinel = true
      })
      commit('setCages', cagesCopy)
    }
  }

})