<template>
  <div class="rack-summary">
    <p>Rack Summary</p>
    <div v-if="cages">
      <p>{{ totalCages }} cages</p>
      <p>{{ inUse }} in use </p>
      <p>{{ totalCages - inUse }} / {{ totalCages }} available </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RackSummary',
  computed: {
    cages() {
      return this.$store.state.cages
    },
    totalCages() {
      return this.cages.length * this.cages[0].length
    },
    inUse() {
      let sum = 0
      this.cages.forEach(row => {
        row.forEach(cage => {
          if (cage.barcode) {
            sum +=1
          }
        })
      })
      return sum
    }
  },
  created() {
    console.log('cages: ', this.cages)
  }
}
</script>


<style scoped>
.rack-summary {
  border: 1px black solid;
  margin-bottom: 10px;
}
</style>