<template>
  <div>
    <div v-if="!accepted" class="welcome">
      <p>
        Welcome to Animal Census version MVP!
      </p>
      <p>
        This is a bare bones minimum viable product (MVP) for what an animal census tool could look like. It's main purpose is to experiment with features and collect feedback to figure out what the most useful Animal Census should do. Some things to know before you begin:
      </p>
      <ul>
        <li>
          The design is rough. There are bugs. We'll polish the site once we narrow down what it should actually do.
        </li>
        <li>
          This version is not connected to a database. Any changes you make
          <ul>
            <li>
            <b>will be lost</b> when you refresh the page 
            </li>
            <li>
            <b>will not</b> be visible to anyone accessing the site
            </li>
          </ul>
        </li>
        <li>
          This version is <b>not</b> designed for mobile devices.
        </li>
        <li>
          This message will appear whenever you refresh the home page
        </li>
      </ul>

      <p>
        Focus feedback on what this tool should do and what it should show you (auto fill bar codes, transfer cages between racks, count of animals by room, etc) and less on design (colors, size, etc).
      </p>
      <p>
        With that all taken care of, welcome to Animal Census 🐁
      </p>

      <b-button class="col-5" type="submit" variant="primary"  @click="accept">Start 🚀</b-button>
    </div>
    <div v-else class='rooms'>
      <div class="create">
        <div v-if="edit">
          <b-button style='margin-right:5px;' type="submit" variant="primary"  @click="editMode">{{ editText }}</b-button>
          <b-button style='margin-left:5px;' type="submit" variant="primary"  @click="createRoom">Create Room</b-button>
        </div>
        <div v-else>
          <b-button class="col-5" type="submit" variant="primary"  @click="editMode">{{ editText }}</b-button>
        </div>
      </div>
      <div class="container">
        <div v-for="group, i in groupedRooms" class="row" :key="i">
          <div v-for="room in group" :key="room.roomId" class="col-sm-6">
            <div v-if="edit">
              <edit-room-card :roomId="room.roomId"/>
            </div>
            <div v-else>
              <room-card :roomId="room.roomId"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomCard from '../components/RoomCard.vue'
import EditRoomCard from '../components/EditRoomCard.vue'
import _ from 'lodash'
import { v4 as uuidV4 } from 'uuid';

export default {
  name: 'Home',
  components: {
    EditRoomCard,
    RoomCard
  },
  computed: {
    accepted() {
      return this.$store.state.accepted
    },
    groupedRooms() {
      return _.chunk(this.rooms, 2)
    },
    editText() {
      return this.edit ? 'Save Changes' : 'Enter Edit Mode'
    },
    rooms() {
      return this.$store.state.rooms
    }
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    createRoom() {
      this.rooms.unshift({
        roomId: uuidV4(),
        room: 'new',
        immuneStatus: 'new',
        availability: '0 / 0',
        racks: "0"
      })
    },
    editMode() {
      this.edit = !this.edit
    },
    accept() {
      this.$store.dispatch('accept')
    }
  },
  created() {
    this.$store.dispatch('loadRooms')
  }
}
</script>

<style scoped>
.rooms {
  margin: auto;
  width: fit-content;
}

.create {
  padding-top: 10px;
  padding-bottom: 10px;
}

.welcome {
  max-width: 500px;
  margin: auto;
  text-align: left;
}
</style>