<template>
  <div class="rack-actions"> 
    <div v-if="selectedPositions.length === 0">
      <p>Select a cage</p>
    </div>
    <div v-else>
      <div class="rack-actions-header">
        <p>{{header}}</p>
      </div>

      <div class="container">
      <b-form @submit.prevent="">
        <div class="form-group row">
          <label for="animalsPerCage" class="col-sm-8 col-form-label">Animals Per Cage:</label>
          <div class="col-sm-4">
            <b-form-input id="animalsPerCage" type="number" v-model="form.animalsPerCage"></b-form-input>
          </div>
        </div>

        <div class="form-group row">
          <label for="totalAnimals" class="col-sm-8 col-form-label">Total Animals:</label>
          <div class="col-sm-4">
            <input type="number" class="form-control" id="totalAnimals" v-model="totalAnimals">
          </div>
        </div>

        <div class="form-group row">
          <label for="startingBarcode" class="col-sm-7 col-form-label">Starting Barcode:</label>
          <div class="col-sm-5">
            <input type="number" class="form-control" id="startingBarcode" v-model="form.startingBarcode">
          </div>
        </div>

        <br>
        <div class="form-group row">
          <b-button class="col-sm-6" type="submit" variant="primary" :disabled="!canEnter" @click="enterCages">Enter {{ numSelected }} cages</b-button>
          <b-button class="col-sm-6" type="submit" variant="primary" :disabled="!canMakeSentinel" @click="makeSentinel">Make {{ numSelected }} sentinels</b-button>
          <b-button class="col-sm-6" type="submit" variant="warning" :disabled="!canRandomize" @click="randomizeCages">Randomize {{ numSelected }} cages</b-button>
          <b-button class="col-sm-6" type="submit" variant="danger" :disabled="!canDelete" @click="deleteCages">Delete {{ numSelected }} cages</b-button>
        </div>
      </b-form>
      </div>
    </div>
  </div>
</template>

<script>
const splitPosKey = (key) => key.split('-').map(i => parseInt(i))

export default {
  name: 'RackActions',
  computed: {
    selectedPositions() {
      return this.$store.state.selectedStack
    },
    header() {
      if (this.$store.state.selectedStack.length > 1) {
        return `${this.$store.state.selectedStack.length} Selected`
      } else {
        return this.$store.state.selectedStack.slice(-1)[0]
      }
    },
    invalidFeedback() {
      return 'invalid'
    },
    totalAnimals: {
      get () {
        return this.form.animalsPerCage * this.numSelected
      },
      
      set (num) {
        this.form.animalsPerCage = num / this.numSelected
      }
    },
    numSelected() {
      return this.$store.state.selectedStack.length
    },
    buttonText() {
      if (this.form.startingBarcode) {
        return `Enter ${this.numSelected} cages`
      } else {
        return `Randomize ${this.numSelected} cages`
      }
    },
    canEnter() {
      // If multiple are selected can only enter if they are consecutive
      // All selected must not have barcode
      return !this.numBarcodesSelected(this.$store.state.selectedStack) &&
             this.form.startingBarcode
    },
    canMakeSentinel() {
      return this.numBarcodesSelected(this.$store.state.selectedStack) ===
             this.$store.state.selectedStack.length &&
             !this.hasRandomSelected(this.$store.state.selectedStack)

    },
    canRandomize() {
      return !this.hasSentinelSelected(this.$store.state.selectedStack)
    }, 
    canDelete() {
      // Check if there are any reasons to block a delete
      return Boolean(this.numBarcodesSelected(this.$store.state.selectedStack))
    }
  },
  data() {
    return {
      form: {
        animalsPerCage: 5,
        totalAnimals: 5,
        startingBarcode: null
      },
    }
  },
  methods: {
    onSubmit() {
      // if random assign barcode Rand. to cage
      // if not randome generate barcodes for cage
      // clear selected
      if (!this.form.startingBarcode) {
        // assign rand barcodes to all cages
        // State knows what cages are selected already
        this.$store.dispatch('randomizeCages')
      } else {
        // generate barcodes for all cages, state knows number of cages
        this.$store.dispatch('generateAndAssignBarcodes', parseInt(this.form.startingBarcode))
      }
      this.clear()
    },
    enterCages() {
      // generate barcodes for all cages, state knows number of cages
      this.$store.dispatch('generateAndAssignBarcodes', parseInt(this.form.startingBarcode))
      this.clear()
    },
    randomizeCages() {
      // assign rand barcodes to all cages
      // State knows what cages are selected already
      this.$store.dispatch('randomizeCages')
      this.clear()
    },
    deleteCages() {
      this.$store.dispatch('deleteCages')
    },
    makeSentinel() {
      this.$store.dispatch('makeCagesSentinel')
    },
    clear() {
      this.form.animalsPerCage = 5,
      this.form.totalAnimals = 5,
      this.form.startingBarcode = ''
    },
    areSelectedPositionsSequential() {
      return true
    },
    numBarcodesSelected(selectedPositions) {
      const cages = this.$store.state.cages
      const result = selectedPositions.filter(pos => {
        const [i, j] = splitPosKey(pos)

        return cages[i][j].barcode
      }).length

      return result
    },
    hasSentinelSelected(selectedPositions) {
      const cages = this.$store.state.cages
      const result = selectedPositions.filter(pos => {
        const [i, j] = splitPosKey(pos)

        return cages[i][j].sentinel
      }).length

      return Boolean(result)
    },
    hasRandomSelected(selectedPositions) {
      const cages = this.$store.state.cages
      const result = selectedPositions.filter(pos => {
        const [i, j] = splitPosKey(pos)

        return cages[i][j].barcode === 'RAND'
      }).length

      return Boolean(result)
    }
  }
}
</script>

<style scoped>
.rack-actions {
  border: 1px black solid;
}

.btn.btn-primary:disabled{
    background-color: #bdbdbd;
}

</style>
