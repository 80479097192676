<template>
  <div class="cageCard">
    <p>Barcode: {{ card.Barcode }}</p>
    <p>Animal Id: {{ card['Animal Id'] }}</p>
    <p>Qty: {{ card.Qty }}</p>
    <p>Sex: {{ card.Sex }}</p>
    <p>Sentinel: {{ card.Sentinel }}</p>
    <p>Comments: {{ card.Comments }}</p>
  </div>
</template>

<script>
export default {
  name: 'CageCard',
  props: {
    card: Object
  }
}
</script>

<style scoped>
.cageCard {
  border: 1px gray solid;
  margin-bottom: 4px;
  border-radius: 4px;
  text-align: left;
  padding-left: 25%;
}

p { margin: 0 }
</style>
