<template>
  <div class='room'>
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'home'}">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Home
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Room {{ room.room }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="create">
      <div v-if="edit">
        <b-button style='margin-right:5px;' type="submit" variant="primary"  @click="editMode">{{ editText }}</b-button>
        <b-button style='margin-left:5px;' type="submit" variant="primary"  @click="createRack">Create Rack</b-button>
      </div>
      <div v-else>
        <b-button style="min-width: 262px;" class="col-5" type="submit" variant="primary"  @click="editMode">{{ editText }}</b-button>
      </div>
    </div>

    <div v-if="racks.length > 1">
      <div class="container">
        <div v-for="group, i in groupedRacks" class="row" :key="i">
          <div v-for="rack in group" :key="rack.rackId" class="col-sm-6">
            <div v-if="edit">
              <edit-rack-card :roomId="roomId" :rackId="rack.rackId" :rack="rack.rack" :type="rack.type" :availability="rack.availability" :animals="rack.animals" :rackChange="rack.rackChange" :sequential="rack.sequential"/>
            </div>
            <div v-else>
              <rack-card :roomId="roomId" :rackId="rack.rackId" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-sm-12">
      <div v-if="edit">
        <edit-rack-card :roomId="roomId" :rackId="racks[0].rackId" :rack="racks[0].rack" :type="racks[0].type" :availability="racks[0].availability" :animals="racks[0].animals" :rackChange="racks[0].rackChange" :sequential="racks[0].sequential"/>
      </div>
      <div v-else>
        <rack-card :roomId="roomId" :rackId="racks[0].rackId" :rack="racks[0].rack" :type="racks[0].type" :availability="racks[0].availability" :animals="racks[0].animals" :rackChange="racks[0].rackChange" :sequential="racks[0].sequential"/>
      </div>
    </div>
  </div>
</template>

<script>
import RackCard from '../components/RackCard.vue'
import EditRackCard from '../components/EditRackCard.vue'
import _ from 'lodash'

export default {
  name: 'Room',
  components: {
    RackCard,
    EditRackCard
  },
  props: {
    roomId: String
  },
  computed: {
    groupedRacks() {
      return _.chunk(this.racks, 2)
    },
    editText() {
      return this.edit ? 'Save Changes' : 'Enter Edit Mode'
    },
    racks() {
      return this.$store.state.racks
    },
    room() {
      return this.$store.state.rooms.find(r => r.roomId === this.roomId)
    }
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    createRack() {
      this.racks.unshift({
        rackId: '1234',
        rack: 'new',
        type: '10x8',
        availability: '0 / 80',
        animals: "150",
        sequential: "10",
        rackChange: "7"
      })
    },
    editMode() {
      this.edit = !this.edit
    }
  },
  created() {
    if (!this.room) {
      this.$store.dispatch('loadRooms')
    }
    this.$store.dispatch('loadRacks')
  }
}
</script>

<style scoped>
.room {
  margin: auto;
  width: fit-content;
}

.create {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>