<template>
  <div class="rackCard">
    <router-link :to="{ name: 'rack', params: {roomId: roomId, rackId: rackId} }">
      <b-card
        v-bind:title="rack.name"
        v-bind:sub-title="rack.type"
        img-src="https://www.jax.org/-/media/0586dfc4d83842cca60fde459c567617.jpg"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
      >
        <b-card-text>
          {{ rack.nextChangeDate }} next change date
          <br>
          {{ rack.sequential }} max order size availble
          <br>
          {{ rack.availability }} Cage(s) available
          <br>
          {{ rack.animals }} animal(s)
        </b-card-text>

        <!-- <b-button href="#" variant="primary">Go somewhere</b-button> -->
      </b-card>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RoomCard',
  props: {
    roomId: String,
    rackId: String
  },
  computed: {
    rack() {
      return this.$store.state.racks.find(r => r.rackId === this.rackId)
    }
  }
}
</script>

<style scoped>
.rackCard {
  border: 1px solid transparent;
  border-radius: 4px;
}

.rackCard:hover {
  border: 1px solid rgb(0, 204, 255);
}
a {
  text-decoration: none;
  color: black;
}

/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: black;
}
</style>