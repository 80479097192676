<template>
  <div class="editRoomCard">
    <b-card
      img-src="https://www.jax.org/-/media/0586dfc4d83842cca60fde459c567617.jpg"
      img-alt="Image"
      img-top
      tag="article"
      style="max-width: 20rem;"
    > 

    <b-row class="my-1">
      <b-col sm="4">
        <label for="input-small">Name:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="input-small" size="sm" placeholder="Room Name" v-model="editRoom"></b-form-input>
      </b-col> 
    </b-row>   

    <b-row class="my-1">
      <b-col sm="4">
        <label for="input-small">Immune Status:</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="input-small" size="sm" placeholder="Immune Status" v-model="editImmuneStatus"></b-form-input>
      </b-col> 
    </b-row>  
      
    <b-card-text>
      {{ room.availability }} Cage(s) available
      <br>
      {{ room.racks }} Rack(s)
    </b-card-text>

    </b-card>
  </div>
</template>

<script>
export default {
  name: 'EditRoomCard',
  props: {
    roomId: String
  },
  computed: {
    room() {
      return this.$store.state.rooms.find(r => r.roomId === this.roomId)
    },
    editRoom: {
      get() {
        return this.room.room
      },
      set(val) {
        this.$store.commit('updateRoom', {
          roomId: this.roomId,
          room: val
        })
      }
    },
    editImmuneStatus: {
      get() {
        return this.room.immuneStatus
      },
      set(val) {
        this.$store.commit('updateRoom', {
          roomId: this.roomId,
          immuneStatus: val
        })
      }
    }
  },
}
</script>

<style scoped>
.editRoomCard {
  border: 1px solid transparent;
  border-radius: 4px;
}

</style>