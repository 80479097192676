<template>
  <div class="container">
    <!--UPLOAD-->
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <h1>Upload pdfs</h1>
      <p>
        Reads pdfs of cage cards and displays select info grouped by the room name
      </p>
      <div class="dropbox" >
        <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files)"
          accept=".pdf" class="input-file">
          <p v-if="isInitial">
            Drag your file(s) here to begin<br> or click to browse
          </p>
          <p v-if="isSaving">
            Uploading {{ fileCount }} files...
          </p>
      </div>
      <div v-if="validationError" class="validationError">
        <p>Cannot upload more than 5 files at once</p>
      </div>
    </form>

    <!--SUCCESS-->
    <div v-if="isSuccess">
      <h2>Found {{ cardCount }} cage card(s) for {{ roomCount }} room(s) from {{ fileCount }} file(s)</h2>
      <p>
        <a href="javascript:void(0)" @click="reset()">Upload again</a>
      </p>
      
      <div v-for="([room, cards], index) in Object.entries(rooms)" v-bind:key="index">
        <div class="room">
          <h3>{{ room }}</h3>
          <div v-for="card in cards" v-bind:key="card.barcode">
            <cage-card :card="card"/>
          </div>
        </div>
      </div>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Upload failed.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset()">Try again</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>
  </div>
</template>

<script>
  import CageCard from '../components/CageCard.vue'
  // swap as you need
  const axios = require('axios')
  const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
  export default {
    name: 'Upload',
    components: {
      CageCard
    },
    data() {
      return {
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'files',
        fileCount: 0,
        rooms: null,
        validationError: false
      }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      },
      cardCount() {
        let count = 0;
        Object.values(this.rooms).forEach(cards => {
          count += cards.length
        })
        return count
      },
      roomCount() {
        return Object.keys(this.rooms).length
      }
    },
    methods: {
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
      },
      async save(formData) {
        // upload data to the server
        this.currentStatus = STATUS_SAVING;
        try {
          const response = await axios.post('/api/upload', formData)

          this.rooms = response.data
          this.currentStatus = STATUS_SUCCESS;
        } catch (err) {
          if (err.response && err.response.data) {

            if (err.response.data.includes('Too many files')){
              this.uploadError = 'Oops, too many files were uploaded! We can currently process up to 5 files at a time.'
            }
            if (err.response.data.includes('Only pdfs are allowed')){
              this.uploadError = 'Oops, only pdfs can be processed 🤨'
            } else {
              this.uploadError = 'Oops! Something went wrong with our server... 😬 We\'ve logged the error and are looking into it 🧐'
            }

          } else {
            this.uploadError = 'Oops! Something went wrong with our server... 😬 We\'ve logged the error and are looking into it 🧐'
          }
          this.currentStatus = STATUS_FAILED;
        } 
      },
      filesChange(fieldName, fileList) {
        // handle file changes
        const formData = new FormData();
        if (!fileList.length) return;

        if (fileList.length > 5) {
          this.validationError = true
          return
        }
        this.validationError = false
        // append the files to FormData
        const uploaded = Array
          .from(Array(fileList.length).keys())
          .filter(x => {
            if (fileList[x].name.includes('.pdf')) {
              formData.append(fieldName, fileList[x], fileList[x].name);
              return true
            }
            return false
          });

        this.fileCount = uploaded.length
        // save it
        this.save(formData);
      }
    },
    mounted() {
      this.reset();
    },
  }
</script>

<style scoped>
  .room {
    margin: auto;
    max-width: 400px;
  }
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  .validationError {
    color: red;
  }
</style>