import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Room from '../views/Room.vue'
import Rack from '../views/Rack.vue'
import Upload from '../views/Upload.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/room/:roomId',
    name: 'room',
    component: Room,
    props: true
  },
  {
    path: '/room/:roomId/rack/:rackId',
    name: 'rack',
    component: Rack,
    props: true
  }, 
  {
    path: '/upload',
    name: 'upload',
    component: Upload
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router