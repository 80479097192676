<template>
  <div>
    <div @click="select($event, row, column)">
      <svg width="60" height="60" :style="selectedBorderStyle" v-bind:id="id" ref="cage">
        <g>
          <rect width="60" height="60" :fill="color" rx="0" ry="0" />
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">{{ barcode }}</text>
        </g>
      </svg>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Cage',
  computed: {
    color() {
      if (this.cage.sentinel) {
        return 'pink'
      }
      if (this.cage.barcode) {
        return '#bdbdbd'
      }
      return 'green'
    },
    selectedBorderStyle() {
      if (this.cage.selected) {
        return 'border:4px solid #6ec5ff'
      } else {
        return 'border:0px solid blue'
      }
    },
    barcode() {
      return this.cage.barcode
    },
    sentinel() {
      return this.cage.sentinel
    },
    cage() {
      return this.$store.state.cages[this.row-1][this.column-1]
    },
    id() {
      return `cage-${this.row-1}-${this.column-1}`
    }
  },
  props: {
    row: Number,
    column: Number
  },
  methods: {
    select(event, row, column) {
      if (row >= 1 && column >= 1) {
        if (event.shiftKey) {
          // select all cells between this and last selected
          // unselect all other cells
          const gridPos = { i: row-1, j: column-1}
          this.$store.dispatch('selectShiftCage', gridPos)
        }
        else if (event.ctrlKey || event.metaKey) {
          // ctrl or cmd + click
          const gridPos = { i: row-1, j: column-1 }
          this.$store.dispatch('selectCtrlCage', gridPos)
        } else {
          // row 0 and column 0 are the labels, not actual cage grid
          // subtracting 1 in call because vue in starts at 1
          // subtract 1 since cage grid is within labels grid
          const gridPos = { i: row-1, j: column - 1}
          this.$store.dispatch('selectCage', gridPos)
        }
      }
    }
  },
  data() {
    return {
      selected: false
    }
  }
}
</script>

<style scoped>
.cage {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
</style>

