<template>
  <div class='rack'>
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'home'}">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Home
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="{ name: 'room', params: {roomId: roomId} }">Room {{ roomId }}</b-breadcrumb-item>
      <b-breadcrumb-item active>Rack {{ rackId }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div v-if="apiState === ENUM.LOADED">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8">
            <div class="container">
              <div v-for="i in cages.length+1" class="row" :key="i">
                <div v-for="j in cages[0].length+1" :key="j" class="col-sm-9r">
                  <!-- column labels -->
                  <svg v-if="(i-1)===0 && (j-1) !== 0" width="60" height="20" class="col-label">
                    <text x="50%" y="100%" dominant-baseline="auto" text-anchor="middle" fill="black">{{ columnToChar(j-1) }}</text>
                  </svg>
                  <!-- row lables -->
                  <svg v-else-if="(j-1) === 0 && (i-1) !== 0" width="60" height="60" class="row-label">
                    <text x="90%" y="50%" dominant-baseline="middle" text-anchor="end" fill="black">{{ i-1 }}</text>
                  </svg>
                  <!-- cages -->
                  <cage v-else-if="(j-1) !== 0 && (i-1) !== 0" :row="i-1" :column="j-1"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row">
              <rack-summary />
            </div>
            <div class="row">
              <rack-actions/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cage from '../components/Cage.vue'
import RackActions from '../components/RackActions.vue'
import RackSummary from '../components/RackSummary.vue'
import ENUM from "@/enums";

export default {
  name: 'Rack',
  props: {
    roomId: String,
    rackId: String,
  },
  components: {
    Cage,
    RackActions,
    RackSummary
  },
  computed: {
    cages() {
      return this.$store.state.cages
    },
    apiState() {
      return this.$store.state.apiState
    }
  },
  data() {
    return {
      width: 8,
      length: 10,
      ENUM
    }
  },
  created() {
    this.$store.dispatch('loadCages')
  },
  methods: {
    columnToChar(col) {
      return String.fromCharCode('a'.charCodeAt(0) + col-1).toUpperCase()
    }
  }
}
</script>

<style scoped>
.rack {
  margin: auto;
  max-width: 1000px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
/* 8 columns */
.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
    position: relative;
    min-height: 1px;
    /* padding: 10px */
}

.col-xs-8r {
    width: 12.5%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-8r {
        width: 12.5%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-8r {
        width: 12.5%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-8r {
        width: 12.5%;
        float: left;
    }
}
/* 9 columns */
.col-xs-9r,
.col-sm-9r,
.col-md-9r,
.col-lg-9r {
    position: relative;
    min-height: 1px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.col-xs-9r {
    width: 11.1%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-9r {
        width: 11.1%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-9r {
        width: 11.1%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-9r {
        width: 11.1%;
        float: left;
    }
}
</style>